import React from "react";
import Layout from "../components/layout";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const IndexPage = () => {
  return (
    <>
      <Layout>
          <article>
            <h1><Trans i18nKey="IndexPage.H1"></Trans></h1>
            <Trans i18nKey="IndexPage.P"></Trans>
          </article>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
